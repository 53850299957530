<template>
  <main class="login">
    <section class="tam-2 cover"></section>
    <section class="alinha-v rel">
      <div class="margem">
        <form @submit.prevent="login">
          <p class="logo">Thalamus</p>
          <fieldset>
            <div class="linha">
              <label class="icone-conta pos-input"></label>
              <input type="text" v-model="email" placeholder="E-mail" />

            </div>
            <div class="linha">
              <label class="icone-senha pos-input"></label>
              <input type="password" v-model="password" placeholder="Senha" />
              <a href="" class="icone-olho" onclick="var inp=this.previousElementSibling;inp.type==='password'?inp.type='text':inp.type='password';return false"></a>
            </div>
          </fieldset>
          <div class="submit">
            <button><span class="icone-avancar direita"></span>Entrar</button>
            <RouterLink to="/esqueci-minha-senha" class="botao">Esqueci minha senha</RouterLink>
          </div>
          <div>
            <LoadingHelper v-show="isLoading"></LoadingHelper>
          </div>
        </form>
      </div>

      <div class="alinha-v abs" id="empresa">
        <div class="margem">
          <h2>Selecione a área</h2>
          <div class="sel-area">
            <a href="javascript:void(0)" v-for="local in locaisList" :key="local.id" @click="selecionaLocal(local.id)" class="item"
              ><span class="icone-avancar direita"></span>{{ local.local_nome }}</a
            >
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import { onMounted, ref } from "vue";
import { createToaster } from "@meforma/vue-toaster";
import { sso } from "roboflex-thalamus-sso-lib";
import LoadingHelper from "../components/helpers/LoadingHelper.vue";
import { useRouter } from "vue-router";
import localService from "../services/localService";

const toaster = createToaster({
  position: "top-right",
  duration: 4000,
});

export default {
  components: {
    LoadingHelper,
  },

  setup() {
    let email = ref("");
    let password = ref("");
    let isLoading = ref(false);
    let locaisList = ref(new Array());
    let usuarioLogado = ref();

    const router = useRouter();

    onMounted(() => {
      sso.logout();
    });

    /**
     * Método responsável por realizar login no SSO
     *
     *
     */
    const login = () => {
      if (!email.value || !password.value) {
        toaster.show(`Preencha e-mail e senha`, { type: "error" });
        return;
      }

      isLoading.value = true;

      try {
        const response = sso.login(email.value, password.value);

        response
          .then((usuLogado) => {
            usuarioLogado.value = usuLogado;
            // Atualizar permissões do usuário
            // const menuUrl = `/api/menu/usuario/${usuarioLogado.id}`;
            //const menuResponse = async () => {await api.get(menuUrl)};
            //const userPermissions = menuResponse.data.map((item) => item.nome.toLowerCase());

            // // Chamar a ação para atualizar as permissões no Vuex
            // this.$store.dispatch('updateUserPermissions', userPermissions);

            isLoading.value = false;
            document.getElementById("empresa").classList.toggle("ativo");
          })
          .catch(() => {
            isLoading.value = false;
            toaster.show(`E-mail e/ou senha estão incorretos!`, { type: "error" });
          });
      } catch (error) {
        isLoading.value = false;
        email.value = "";
        password.value = "";
        toaster.show(`E-mail e/ou senha estão incorretos!`, { type: "error" });
      }
    };

    const selecionaLocal = (local) => {
      /**
       * Registrando o local selecionado na sessão no cookie
       *
       */
      sso.registrarLocal(local);

      /**
       * Direciona o usuario para a home
       */
      router.push({ name: "home" });
    };

    /**
     * Método responsavel por carregar os locais (Empresas), que o usuário quer acessar
     *
     */
    const carregaLocais = () => {
      try {
        localService
          .buscarLocaisMock()
          .then((data) => {
            locaisList.value = data;
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.error("Erro ao buscar empresas", error);
        toaster.show(`Erro ao buscar empresa`, { type: "error" });
      }
    };

    carregaLocais();

    return {
      login,
      selecionaLocal,
      carregaLocais,
      email,
      password,
      isLoading,
      locaisList,
    };
  },
};
</script>

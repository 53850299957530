import { sso } from "roboflex-thalamus-sso-lib";
import { api } from "roboflex-thalamus-request-handler";


const moduloService = {
  buscarModulos: async () => {
    
    var resp = await api.post("funcionalidade/cards/usuario", {
      usuario_id: sso.getUsuarioLogado().id,
    });
    return resp.data;
  },
};

export default moduloService;

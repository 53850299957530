import { api } from "roboflex-thalamus-request-handler"

const lista = [{
    id: 1,
    local_nome: "Roboflex"
},
{
    id: 2,
    local_nome: "Zontec"
}]

const localService = {
    buscarLocais: () => {
        return api.get('/local')
    },
    buscarLocaisMock: () => {
        return new Promise((resolve, reject) => {
            resolve(lista),
            reject(false)
        })
    }
}

export default localService
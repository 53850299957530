<template>
  <router-view />
</template>

<style>
*::-webkit-scrollbar {
    width: 10px;
}

*::-webkit-scrollbar-thumb {
    background-color: var(--cor-separador);
    border-radius: 20px;
}
</style>

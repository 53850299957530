<template>
  <header>
    <div class="linha margem">
      <div class="coluna">
        <a class="logo">Thalamus</a>
      </div>

      <div class="coluna">
        <a
          href="#"
          class="avatar direita"
          onclick="document.getElementById('usuario').classList.toggle('none');this.classList.toggle('ativo');return false"
          :title="user.nome"
          ><img
            src="https://cdn-icons-png.flaticon.com/128/709/709722.png"
            style="height: 26px; width: 26px"
            :class="theme == 'dark' ? 'white-profile' : 'none'"
          />
        </a>

        <nav class="jm jm-avatar none" id="usuario">
          <div class="separador margem alinha-centro negrito">
            {{ user.nome }}
          </div>
          <div class="separador">
            <a
              href="#"
              onclick="document.documentElement.classList.toggle('dark');this.classList.toggle('ativo');return false"
              :class="theme == 'dark' ? 'ativo' : ''"
              @click="setTheme()"
              ><span class="direita toggle"></span>Tema escuro</a
            >
          </div>
          <div class="separador bg-cinza">
            <!-- <a href="#">Ajuda</a> -->
            <!-- <a href="#">Alterar senha</a> -->
            <a
              :href="getLoginUrl()"
              @click="logout()"
              >Sair</a
            >
          </div>
        </nav>
        <!-- <a
          href="#"
          class="icone-notif direita"
          title="Notificações"
          onclick="document.getElementById('notificacoes').classList.toggle('none');this.classList.toggle('ativo');return false"
        ></a> -->

        <a href="#" class="icone-notif direita" title="Notificações"></a>

        <!-- <nav class="jm jm-notif none" id="notificacoes">
          <div class="separador">
            <a href="#"
                  ><span class="fonte-fraca fonte-menor direita">12min</span>Título
                  da notificação</a
                >
                <a href="#"
                  ><span class="fonte-fraca fonte-menor direita">2h</span>Título da
                  notificação</a
                >
          </div>
        </nav> -->
      </div>
    </div>
  </header>
</template>

<script>
import { sso } from "roboflex-thalamus-sso-lib";

export default {
  data() {
    return {
      user: null,
      theme: null,
      menuAtivo: "Painel Injetora",
    };
  },
  created() {
    this.user = sso.getUsuarioLogado();
    this.theme = localStorage.getItem("tema");
    if (this.theme == null) {
      localStorage.setItem("tema", "light");
    } else {
      document.documentElement.classList.toggle(this.theme);
    }
  },
  methods: {
    logout() {
      sso.logout();
    },
    setTheme() {
      if (this.theme == "light") {
        localStorage.setItem("tema", "dark");
        this.theme = "dark";
      } else {
        localStorage.setItem("tema", "light");
        this.theme = "light";
      }
    },
    getLoginUrl() {
      if (
        process.env.NODE_ENV == "staging" ||
        process.env.NODE_ENV == "development"
      ) {
        return "http://portal-homologacao.thalamus.ind.br/#/login";
      } else {
        return "https://portal.thalamus.ind.br/#/login";
      }
    },
  },
};
</script>

<style scoped>
.white-profile {
  filter: invert(100%);
}

.avatar {
  border: 2px solid var(--cor-primaria);
  margin-top: 3px;
}
</style>

<template>
  <HeaderHome />

  <body class="inicial">
    <main>
      <section>
        <div class="titulo">
          <div class="container margem alinha-centro">
            <h2>Selecione o módulo</h2>
          </div>
        </div>
        <div class="margem container">
          <div v-if="dataLoaded" class="grid-4">
            <a v-for="modulo in menus" :key="modulo.id" :href="modulo.URL" class="card bloco">
              <figure>
                <!-- <img :src="`@/assets/img/injetora.png`"> -->
                <!-- <img :src="'/public/img/'+modulo.caminho_img" /> -->
                <!-- <img :src="injetoras[index]" /> -->
                <img :src="getImageSrc(modulo.caminho_img)" />
                <figcaption class="margem abs">{{ modulo.nome }}</figcaption>
              </figure>
            </a>
          </div>
        </div>
      </section>
    </main>
  </body>
</template>

<script>
import moduloService from "@/services/moduloService";
import HeaderHome from "@/components/HeaderHome.vue";
// import getFuncionalidades from "@/services/funcionalidadeService";
import { sso } from "roboflex-thalamus-sso-lib";
import { ref, onMounted } from "vue";

export default {
  name: "HomeView",
  components: {
    HeaderHome,
  },
  data() {
    return {
      dataLoaded: false,
      // injetoras: [
      //   '/src/assets/img/sgi.png',
      //   '../assets/img/projeto.png',
      //   '../assets/img/monitoramento.png',
      //   '../assets/img/acesso.webp',
      //   '../assets/img/injetora.jpg',
      // ],
    };
  },

  methods: {
    getImageSrc(imagePath) {
      try {
        return require(`../assets/img/${imagePath}`);
      } catch (e) {
        console.error("Image not found:", imagePath);
        return "";
      }
    },
  },

  async created() {
    this.menus = await moduloService.buscarModulos();
    this.dataLoaded = true;
    console.log(this.menus);
  },

  setup() {
    let modulosList = ref(new Array());

    // const carregarModulos = async () => {
    //   await moduloService.buscarModulos().then((data) => {
    //     console.log(data)
    //     modulosList.value = data;
    //   });
    // };
    var menus = null;
    moduloService.buscarModulos().then((resp) => {
      console.log(menus);
      menus = resp;
    });

    const funcionalidades = ref([]);
    const showIndicadores = ref(false);
    onMounted(async () => {
      const usuarioLogado = sso.getUsuarioLogado();
      var userId = usuarioLogado.id;
      if (userId) {
        // try {
        //   const data = await getFuncionalidades(userId)
        //   funcionalidades.value = data
        //   showIndicadores.value = funcionalidades.value.funcionalidade.includes('Indicadores')
        // } catch (error) {
        //   console.error('Erro ao carregar funcionalidades:', error)
        // }
      }
    });
    return {
      // carregarModulos,
      menus,
      modulosList,
      funcionalidades,
      showIndicadores,
    };
  },
};
</script>

<style scoped>
.card img {
  width: 100%;
  height: 250px;
}
</style>

import axios from "axios";

   const apiR = axios.create({
      baseURL:  getUrl(),
      devURL: getUrl(),
 });

 function getUrl() {
  if(process.env.NODE_ENV == "staging" || process.env.NODE_ENV == "development"){
    return "http://api-homologacao.thalamus.ind.br/api/"
  } else {
    return "https://api.thalamus.ind.br/api/"
  }
 }

//local de armazenamento das fotos de visitante e colaborador
export const urlFoto = {
  caminhoFoto: process.env.VUE_APP_ROOT_STORAGE,
};

export default apiR;